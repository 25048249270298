<template>
  <div class="multiple-select">
    <div class="multiple-select__list">
      <div
        class="multiple-select__list__field"
        v-for="(val, index) in innerValue"
        :key="index"
      >
        <Select
          :value="val"
          :label="index === 0 ? label : undefined"
          :options="freeOptions(val)"
          :isError="isError"
          :isDisabled="isDisabled"
          @input="handleSelect($event, index)"
        />
        <Icon
          v-if="!isDisabled"
          name="trash"
          isClickable
          color="secondary-400"
          @click="deleteValue(index)"
        />
      </div>
      <div v-if="isAddActive" class="multiple-select__list__field">
        <Select
          :label="!innerValue.length ? label : undefined"
          :placeholder="placeholder"
          :options="freeOptions()"
          :isError="isError"
          :isDisabled="isDisabled"
          @input="handleSelect($event)"
        />
        <Icon
          v-if="innerValue.length"
          name="trash"
          isClickable
          color="secondary-400"
          @click="deleteValue()"
        />
      </div>
    </div>
    <IconButton
      v-if="!isDisabled && !isAddActive && freeOptions().length"
      icon="plus"
      @click="isAddActive = true"
    >
      {{ addButtonText }}
    </IconButton>
  </div>
</template>

<script>
export default {
  name: "MultipleSelect",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    addButtonText: {
      type: String,
      default: "Add",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAddActive: !this.value.length,
    };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    freeOptions(val) {
      return this.options.filter(
        (option) =>
          option.value === val || !this.innerValue.includes(option.value)
      );
    },
    handleSelect(newValue, index) {
      const copy = [...this.innerValue];
      if (index === undefined) {
        copy.push(newValue);
        this.isAddActive = false;
      } else {
        copy[index] = newValue;
      }
      this.innerValue = copy;
    },
    deleteValue(index) {
      if (index === undefined) {
        this.isAddActive = false;
      } else {
        const copy = [...this.innerValue];
        copy.splice(index, 1);
        this.innerValue = copy;
        if (!copy.length) {
          this.isAddActive = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-select {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__field {
      display: flex;
      align-items: flex-end;
      gap: 24px;

      > :first-child {
        flex: 1;
      }

      > :not(:first-child):last-child {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
